@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500');

$darkblue: #0e58a3;
$blue: #0575e6;
$green: #00f260;
$yellow: #ffff1a;
$black: #18191b;
$black-x: #1d1e22;
$black-m: #212224;
$black-l: #3b3e43;
$darkGray: #686e78;
$gray: #a1a8b4;
$gray-m: #a1a8b4;
$gray-l: #cbcfd6;
$gray-sl: #f0f0f4;
$white: #ffffff;

$trs-time: 0.45s;

.white {
  color: $white;
}

.gray {
  color: $gray;
}

.darkGray {
  color: $darkGray;
}

.blue {
  color: $blue;
}

.black-x {
  color: $black-x;
}

.black-m {
  color: $black-m;
}

.yellow {
  color: $yellow;
}

.f-b {
  font-family: 'Bebas Neue', cursive;
}

.fw-3 {
  font-weight: 300;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: bold;
}

.fz-55 {
  font-size: 55px;
  line-height: 70px;
}

.fz-41 {
  font-size: 41px;
  line-height: 56px;
}

.fz-36 {
  font-size: 36px;
  line-height: 49px;
}

.fz-33 {
  font-size: 33px;
  line-height: 56px;
}

.fz-24 {
  font-size: 24px;
  line-height: 33px;
}

.fz-18 {
  font-size: 18px;
  line-height: 21px;
}

.fz-15 {
  font-size: 15px;
  line-height: 28px;
}

.fz-15--23 {
  font-size: 15px;
  line-height: 23px;
}

.fz-14 {
  font-size: 14px;
  line-height: 24px;
}

.fz-13 {
  font-size: 13px;
  line-height: 28px;
}

.fz-13--18 {
  font-size: 13px;
  line-height: 18px;
}

.fz-12 {
  font-size: 12px;
  line-height: 23px;
}

.fz-10 {
  font-size: 10px;
  line-height: 23px;
}

.ls-4 {
  letter-spacing: 0.04em;
}

.ls-2 {
  letter-spacing: 0.02em;
}

/*** MIXINS ***/
@mixin trs-color {
  transition-property: color;
  transition-duration: $trs-time;
  transition-timing-function: ease-in-out;
}

@mixin trs-all {
  transition-property: all;
  transition-duration: $trs-time;
  transition-timing-function: ease-in-out;
}

@mixin animation-slider {
  animation-delay: 0.5s;
  animation-duration: $trs-time;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

section {
  overflow-x: hidden;
}
