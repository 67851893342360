@import "new-landing-base";
.main-1 {
  padding-top: 95px;
  height: 704px;
  background: url(../img/main-1--bg.webp) no-repeat center top / cover;
  overflow-x: hidden;
  .container {
    position: relative;
    height: 100%;
    .slider {
      .slider-item {
        display: none;
        .left {
          padding-top: 140px;
          max-width: 700px;
          .title {
            margin-bottom: 20px;
            /*transform: translateX(-280%);*/
            transition-property: transform;
            transition-duration: $trs-time;
            position: relative;
            z-index: 155;
          }
          .subtitle {
            margin-bottom: 34px;
            /*transform: translateX(-280%);*/
            max-width: 620px;
            position: relative;
            z-index: 200;
          }
          > .btn,
          .quality {
            /*transform: translateX(-280%);*/
          }
        }

        &.active {
          display: flex;
        }
      }
      .slider-item--nav {
        position: absolute;
        z-index: 150;
        bottom: 90px;
        right: 100px;
        a {
          width: 40px;
          height: 40px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 993px) {
  header {
    .navbar-collapse {
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(120%);
      @include trs-all();
      max-width: 320px;
      width: 100%;
      background: $white;
      padding: 110px 30px 0 30px;
      height: 100vh;
      box-shadow: 0px 0px 13px rgba(39, 87, 136, 0.55);
      .nav-link {
        color: $black;
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .buttons {
        position: absolute;
        bottom: 30px;
        width: calc(100% - 60px);
        .btn {
          width: 100%;
          max-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    &.active {
      .navbar-collapse {
        transform: translateX(0) !important;
      }
    }
  }
  .main-1 {
    .container {
      .slider {
        .slider-item {
          .left {
            padding-top: 35px;
            max-width: 700px;
          }
          .right {
            .img {
              right: 24%;
              left: 40%;
            }
            .slider-item--info {
              bottom: 40px;
            }
          }
        }
        .slider-item--nav {
          bottom: 40px;
          right: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .fz-33 {
    font-size: 26px;
    line-height: 40px;
  }
  .main-1 {
    height: auto;
    padding-bottom: 78px;

    .container {
      .slider {
        .slider-item {
          .left {
            padding-top: 35px;
            max-width: 700px;
          }

          .right {
            display: none;
          }
        }

        .slider-item--nav {
          display: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .main-1 {
    .container {
      .slider {
        .slider-item {
          .left {
            .title {
              font-size: 26px;
              line-height: 38px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}


.btn {
  border: 1px solid #0575e6;
  border-radius: 12px;
  transition-property: all;
  transition-duration: 0.45s;
  transition-timing-function: ease-in-out;
  position: relative;
}
.btn:hover {
  background-color: #0575e6;
}
.btn.gradient {
  background: linear-gradient(90deg, #00f260 0%, #0575e6 100%);
  border: none;
  color: #ffffff;
  padding: 18px 40px;
  overflow: hidden;
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
.btn.gradient span {
  position: relative;
  z-index: 50;
}
.btn.gradient::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #0575e6;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.45s;
  transition-timing-function: ease-in-out;
}
.btn.gradient.ghost {
  color: #3b3e43;
  letter-spacing: 0.02em;
  font-weight: 600;
  text-transform: none;
}
.btn.gradient.ghost::after {
  margin: 1px;
  background: #ffffff;
  opacity: 1;
  border-radius: 12px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
.btn.gradient:hover::after {
  opacity: 1;
}
.btn.gradient:hover.ghost {
  color: #ffffff;
}
.btn.gradient:hover.ghost::after {
  margin: 0;
  background: #0e58a3;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .main-2 {
    padding-top: 62px;
  }
}

.tag {
  background: #f0f0f4;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 14px;
  height: 28px;
  margin-bottom: 30px;
}

header {
  width: 100%;
  position: absolute;
  z-index: 500;
}